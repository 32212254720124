import { pathOr } from 'ramda'
import React from 'react'

import { defaultMatchScore } from '../../constants/constants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { BlasterPeer } from '../../reducers/blasterPeersSlice'
import socialSlice from '../../reducers/socialSlice'
import { selectPlaylistMap } from '../../selectors/match-selectors'
import {
  selectIsShowMatchPlayers,
  selectIsUserMatchExpanded,
  selectMatchDisplayStyle,
} from '../../selectors/social-selectors'
import { MatchDisplayStyle, MatchInfo, PlayerStatus, TrackStatsMap } from '../../types'
import BlastRecord from './BlastRecord'
import PlaylistContainer from './PlaylistContainer'
import { selectTrackStats } from '../../selectors/leaderboard-selectors'
import PlaylistContainerWithTrackPlayers from './PlaylistContainerWithTrackPlayers'

type Props = {
  username: string
  playerMatchStatus: PlayerStatus
  matchPeer: BlasterPeer
  matchSlug: string
  matchInfo: MatchInfo
  userRank: number
}
const PeerMatchContainer = ({
  username,
  playerMatchStatus,
  matchPeer,
  matchSlug: compoundMatchSlug,
  matchInfo,
  userRank,
}: Props) => {
  const dispatch = useAppDispatch()
  const isUserMatchExpanded = useAppSelector(
    selectIsUserMatchExpanded({ username, matchSlug: compoundMatchSlug })
  )
  const playlistMap = useAppSelector(selectPlaylistMap(compoundMatchSlug))
  const { ownedMatchSlug } = matchPeer
  const isRootOwnedMatch = compoundMatchSlug === ownedMatchSlug
  const isShowMatchPlayers = useAppSelector(
    selectIsShowMatchPlayers({ matchSlug: compoundMatchSlug })
  )
  const matchDisplayStyle = useAppSelector(
    selectMatchDisplayStyle({ matchSlug: compoundMatchSlug })
  )
  const isShowChildren = isRootOwnedMatch || isUserMatchExpanded
  const { playlistOrder = [] } = matchInfo
  const { matchScore, playlistScores, trackScores, name: playerName = username } = playerMatchStatus
  const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
  const trackStats: TrackStatsMap = useAppSelector(selectTrackStats({ matchOwner, matchSlug }))
  const getPlaylists = () => {
    return playlistOrder.map((playlistSlug) => {
      const playlistInfo = playlistMap[playlistSlug]
      if (!playlistInfo) {
        // TODO: better way to detect whether loaded?
        // console.log(`no such playlist: ${matchSlug}/${playlistSlug}`)
        return null
      }
      const playlistScore = pathOr(defaultMatchScore(), [playlistSlug], playlistScores)
      const key = `${username}-${compoundMatchSlug}-${playlistSlug}`
      if (matchDisplayStyle === MatchDisplayStyle.MONITOR) {
        return (
          <PlaylistContainerWithTrackPlayers
            key={key}
            username={username}
            matchInfo={matchInfo}
            playlistInfo={playlistInfo}
            trackScores={trackStats}
          />
        )
      }
      return (
        <PlaylistContainer
          key={key}
          username={username}
          matchInfo={matchInfo}
          playlistInfo={playlistInfo}
          trackScores={trackScores}
          playlistScore={playlistScore}
          isScoredTracksOnly={isShowMatchPlayers}
        />
      )
    })
  }

  const onToggleChildren = () => {
    const isExpanded = !isShowChildren
    dispatch(
      socialSlice.actions.toggleUserExpanded({
        username,
        matchSlug: compoundMatchSlug,
        isExpanded,
      })
    )
  }

  if (!matchInfo) {
    console.log(`${username} no match info for [${compoundMatchSlug}]`)
    return null
  }

  return (
    <div className="chatUserSongsWrapper">
      {!isRootOwnedMatch && isShowMatchPlayers && (
        <BlastRecord
          username={playerName}
          userRank={userRank}
          matchInfo={matchInfo}
          matchStatus={matchScore}
          onToggleChildren={onToggleChildren}
          isChildrenVisible={isShowChildren}
        />
      )}
      {isShowChildren && getPlaylists()}
    </div>
  )
}

export default PeerMatchContainer
