import React from 'react'

import { useAppDispatch, useAppSelector } from '../../hooks'
import socialSlice from '../../reducers/socialSlice'
import { selectIsPlaylistTrackExpanded } from '../../selectors/social-selectors'
import { MatchInfo, PlaylistInfo, TrackStats } from '../../types'
import BlastRecord from './BlastRecord'
import { selectTrackInfo } from '../../selectors/blaster-peer-selectors'
import { ScoreProgressBar } from './ProgressBar'

type Props = {
  trackStats: TrackStats
  matchSlug: string
  matchInfo: MatchInfo
  playlistInfo: PlaylistInfo
}
const TrackWithMatchPlayers = ({
  trackStats: { trackSlug, playerTopScores },
  matchSlug,
  matchInfo,
  playlistInfo,
}: Props) => {
  const dispatch = useAppDispatch()
  const { slug: playlistSlug } = playlistInfo
  const isPlaylistTrackExpanded = useAppSelector(
    selectIsPlaylistTrackExpanded({ matchSlug, playlistSlug, trackSlug })
  )
  const [hostname] = matchSlug.split('/')
  const trackInfo = useAppSelector(selectTrackInfo(hostname, trackSlug))
  if (!trackInfo) {
    console.log(`no track info for ${hostname} - ${trackSlug}?`)
    return null
  }
  const isRootOwnedMatch = false // matchSlug === ownedMatchSlug
  const isShowChildren = isRootOwnedMatch || isPlaylistTrackExpanded
  const getPlayerScores = () => {
    return playerTopScores.map((topScoreStat) => {
      const { playerName, playerId, playStatus } = topScoreStat
      const key = `${trackSlug}-${playerId}`

      return (
        <div key={key} className="playerScoreWrapper">
          <div className="playerName">{playerName}</div>
          <div className="chatSongSummaryTopScore">
            <ScoreProgressBar scoreInfo={playStatus} isMini isUseTopScore={false} isShowRank />
          </div>
        </div>
      )
    })
  }

  const onToggleChildren = () => {
    const isExpanded = !isShowChildren
    dispatch(
      socialSlice.actions.toggleTrackWithPlayersExpanded({
        matchSlug,
        playlistSlug,
        trackSlug,
        isExpanded,
      })
    )
  }

  return (
    <div className="chatUserSongsWrapper">
      {!isRootOwnedMatch && (
        <BlastRecord
          username={'??'}
          matchInfo={matchInfo}
          playlistInfo={playlistInfo}
          trackInfo={trackInfo}
          onToggleChildren={onToggleChildren}
          isChildrenVisible={isShowChildren}
          hasDetails={false}
        />
      )}
      {isShowChildren && getPlayerScores()}
    </div>
  )
}

export default TrackWithMatchPlayers
