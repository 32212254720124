import { mergeDeepRight } from 'ramda'
import { createSlice } from '@reduxjs/toolkit'

type SidebarStatus = {
  isActive: boolean
  isExpanded: boolean
  isShowActiveScore: boolean
}
export interface SidebarState {
  [matchSlug: string]: SidebarStatus & {
    players: {
      [username: string]: SidebarStatus & {
        playlists: {
          [playlistSlug: string]: SidebarStatus & {
            tracks: {
              [trackSlug: string]: SidebarStatus
            }
          }
        }
      }
    }
    playlists: {
      [playlistSlug: string]: SidebarStatus & {
        tracks: {
          [trackSlug: string]: SidebarStatus & {
            players: {
              [username: string]: SidebarStatus
            }
          }
        }
      }
    }
  }
}
const initialState: SidebarState = {}

const socialSlice = createSlice({
  name: 'social',
  initialState,
  reducers: {
    toggleMatchExpanded: (state, action) => {
      const { matchSlug, isExpanded } = action.payload
      const updatedMatch = { [matchSlug]: { isExpanded } }
      return mergeDeepRight(state, updatedMatch)
    },
    toggleShowMatchPlayers: (state, action) => {
      const { matchSlug, isShowPlayers } = action.payload
      const updatedMatch = { [matchSlug]: { isShowPlayers } }
      return mergeDeepRight(state, updatedMatch)
    },
    setMatchDisplayStyle: (state, action) => {
      const { matchSlug, matchDisplayStyle } = action.payload
      const updatedMatch = { [matchSlug]: { matchDisplayStyle } }
      return mergeDeepRight(state, updatedMatch)
    },
    toggleTrackWithPlayersExpanded: (state, action) => {
      const { matchSlug, playlistSlug, trackSlug, isExpanded } = action.payload
      const updatedMatch = {
        [matchSlug]: {
          playlists: {
            [playlistSlug]: {
              tracks: {
                [trackSlug]: { isExpanded },
              },
            },
          },
        },
      }
      return mergeDeepRight(state, updatedMatch)
    },
    toggleUserExpanded: (state, action) => {
      const { username, matchSlug, isExpanded } = action.payload
      const updatedMatch = {
        [matchSlug]: { players: { [username]: { isExpanded } } },
      }
      return mergeDeepRight(state, updatedMatch)
    },
    togglePlaylistExpanded: (state, action) => {
      const { username, matchSlug, playlistSlug, isExpanded } = action.payload
      const updatedMatch = {
        [matchSlug]: {
          players: {
            [username]: {
              playlists: {
                [playlistSlug]: { isExpanded },
              },
            },
          },
        },
      }
      return mergeDeepRight(state, updatedMatch)
    },
    toggleTrackExpanded: (state, action) => {
      const { username, matchSlug, playlistSlug, trackSlug, isExpanded } = action.payload
      const updatedMatch = {
        [matchSlug]: {
          players: {
            [username]: {
              playlists: {
                [playlistSlug]: {
                  tracks: {
                    [trackSlug]: { isExpanded },
                  },
                },
              },
            },
          },
        },
      }
      return mergeDeepRight(state, updatedMatch)
    },
    toggleTrackShowActiveScore: (state, action) => {
      const { username, matchSlug, playlistSlug, trackSlug, isShowActiveScore } = action.payload
      const updatedMatch = {
        [matchSlug]: {
          players: {
            [username]: {
              playlists: {
                [playlistSlug]: {
                  tracks: {
                    [trackSlug]: { isShowActiveScore },
                  },
                },
              },
            },
          },
        },
      }
      return mergeDeepRight(state, updatedMatch)
    },
    clearActiveTrack: (state, action) => {
      const { username, matchSlug, playlistSlug, trackSlug } = action.payload
      if (!username || !matchSlug || !playlistSlug || !trackSlug) {
        return state
      }
      const updatedMatch = {
        [matchSlug]: {
          isActive: false,
          players: {
            [username]: {
              isActive: false,
              playlists: {
                [playlistSlug]: {
                  isActive: false,
                  tracks: {
                    [trackSlug]: {
                      isActive: false,
                    },
                  },
                },
              },
            },
          },
        },
      }
      const result = mergeDeepRight(state, updatedMatch)
      return result
    },
    revealTrack: (state, action) => {
      const { username, matchSlug, playlistSlug, trackSlug, owner } = action.payload
      const isOwned = username === owner
      const updatedMatch = {
        [matchSlug]: {
          isActive: true,
          isExpanded: true,
          isShowActiveScore: isOwned,
          players: {
            [username]: {
              isActive: true,
              isExpanded: true,
              isShowActiveScore: isOwned,
              playlists: {
                [playlistSlug]: {
                  isActive: true,
                  isExpanded: true,
                  isShowActiveScore: isOwned, // TODO: or if user has saved timing
                  tracks: {
                    [trackSlug]: {
                      isActive: true,
                      isExpanded: true,
                      isShowActiveScore: isOwned,
                    },
                  },
                },
              },
            },
          },
        },
      }
      const result = mergeDeepRight(state, updatedMatch)
      return result
    },
  },
})

export default socialSlice
