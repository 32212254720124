export type AudienceType = 'pub' | 'couch' | 'solo' | 'prod'
export type ModeType = 'play' | 'viz' | 'edit' | 'spell' | 'multi'
export enum MatchDisplayStyle {
  BROWSE,
  MONITOR,
  LEADERBOARD,
}

export type PlayerPath = {
  matchOwner: string
  matchSlug: string
  username: string
  player: string
}
export type PlayerTrackPath = {
  matchOwner: string
  matchSlug: string
  username: string
  player: string
  trackSlug: string
}

export type MinimalTrackInfo = {
  artist: string
  title: string
}
export type TrackInfo = MinimalTrackInfo & {
  slug: string
  owner: string
  timedWordCount: number
  wordCount: number
  timestamp: number
  links: string
  duration: number
  maxPoints: number
  dirtyState?: DirtyState
  remotePath?: string
  localPath?: string
  remoteImagePath?: string
  localImagePath?: string
  matchSlug?: string
  lyrics?: string
}
export type ExtendedTrackInfo = TrackInfo & {
  version: string
  paths: {
    infoUrl: string
    textUrl: string
    audioUrl: string
    lrcUrl: string
    imageUrl: string
  }
  imageAttributes: {
    blendMode: string | null
    theme: string | null
  }
  timestamps: {
    remoteAudioTimestamp: number
    remoteImageTimestamp: number
    remoteLyricsTimestamp: number
    remoteTimingTimestamp: number
  }
}
export type AudioData = {
  owner?: string
  title?: string
  artist?: string
  links?: string
  localPath?: string
  remotePath?: string
  lyrics?: string
  wordCount?: number
  timedWordCount?: number
  remoteImagePath?: string
  remoteLyricsPath?: string
  remoteTimingPath?: string
  remoteLyricsTimestamp?: number
  remoteTimingTimestamp?: number
  remoteAudioTimestamp?: number
  remoteImageTimestamp?: number
  timing?: string
  manifest?: Object
  isTimingDirty?: boolean
  isInfoDirty?: boolean
  dirtyState?: DirtyState
  duration?: number
  version?: number
}
export type AudioDataMap = { [key: string]: AudioData }
export type TrackInfoMap = { [key: string]: TrackInfo }
export type ExtendedTrackInfoMap = { [key: string]: ExtendedTrackInfo }
export type LocalTiming = {
  timing: string
  scoreSeconds: number
}
export type TimingMap = { [key: string]: LocalTiming }

export type PlaylistInfo = {
  prefix?: string
  slug: string
  title: string
  owner?: string
  trackOrder: string[]
  perTrackPlayLimit: number
  containsCurrentTrack?: boolean
  wordCount: number
  timestamp: number
}

export type MinimalPlaylistInfo = {
  index: number
  slug: string
  title: string
  tracks: TrackInfo[]
  perTrackPlayLimit: number
}

export type PlaylistInfoMap = { [key: string]: PlaylistInfo }

export type PlayerStatus = {
  name: string
  matchScore: PlayStatus
  playlistScores: PlayStatusMap
  trackScores: PlayStatusMap
}
export type PlayerStatusMap = { [key: string]: PlayerStatus }
export type UserMatchStatusMap = { [key: string]: PlayerStatusMap }

export type GuestPlayer = {
  slug: string
  name: string
}

export type HostVisibility = 'play' | 'demo' | 'hide'

export type MatchInfo = {
  slug: string
  title: string
  hostVisibility: HostVisibility
  isShareable: boolean
  isCopyable: boolean
  playlistOrder: string[]
  playlists: PlaylistInfoMap
  guestOrder: string[]
}
export type UserMatchInfo = {
  id: string
  title: string
  matchOwner: string
  matchSlug: string
  inviteKey: string
  isActive: boolean
}
export type ActiveMatchInfo = {
  matchOwner: string
  matchSlug: string
  inviteKey: string
}
export type UserMatchesInfo = {
  active: null | ActiveMatchInfo[]
  archived: ActiveMatchInfo[]
}

export type MatchStatus = {
  info: MatchInfo
  players: UserMatchStatusMap
  invites: { [key: string]: MinimalInviteInfo }
  leaderboard: string[]
}
export type MatchStatusMap = { [key: string]: MatchStatus }
export type MatchPath = { matchOwner: string; matchSlug: string }
export type PlaylistPath = { username: string; matchSlug: string; playlistSlug: string }
export type TrackPath = {
  username?: string
  matchSlug: string
  playlistSlug?: string
  trackSlug?: string
}

export type InvitePath = {
  matchOwner: string
  matchSlug: string
  inviteKey: string
}

export type MinimalInviteInfo = {
  slug: string
  created: number
  expires: number
  prompt?: string
}
export type MinimalInviteInfoMap = { [key: string]: MinimalInviteInfo }

export type InviteInfo = MinimalInviteInfo & {
  matchOwner: string
  matchSlug: string
  matchTitle: string
}

export type InviteInfoMap = { [key: string]: InviteInfo }
export type InvitesFromMap = { [key: string]: InviteInfoMap }
export type DirtyState = {
  isAudioDirty: boolean
  isInfoDirty: boolean
  isLyricsDirty: boolean
  isTimingDirty: boolean
  isImageDirty: boolean
}

export type SettingsActions = {
  copyReference: () => void
  toggleTimes: (isOn: boolean) => void
  toggleMasked: (isOn: boolean) => void
  toggleVisualPulse: (isOn: boolean) => void
  toggleAudioPulse: (isOn: boolean) => void
}

export type Target = {
  sectionIndex: number
  lineIndex: number
  wordIndex: number
  elem: HTMLElement | null
  id?: string
}

export type ModelPart = {
  eventID: number
  label: string
  elem: HTMLElement | null
  isSection: boolean
  isLine: boolean
  referenceTime: number | null
  time: number | null
  score: Score | undefined
  index: number
}
export type Section = ModelPart & {
  lines: Line[]
  container: HTMLDivElement | null
}
export type Line = ModelPart & {
  sectionIndex: number
  words: Word[]
}

export type Word = ModelPart & {
  sectionIndex: number
  lineIndex: number
}

export type ScoreRank = 'perfect' | 'excellent' | 'good' | 'bad'

export type Score = {
  deltaTime: number
  value: number
  rank: ScoreRank
  rankClass: string[]
}

export type ScoreCounters = {
  perfect: number
  excellent: number
  good: number
  bad: number
}
export type ScoreCountersMap = { [slug: string]: ScoreCounters }

export type ScoreDelta = {
  oldValue?: number
  oldRank?: ScoreRank
  newValue: number
  newRank: ScoreRank
  isRight: boolean
}
export type PlayStatusTrackInfo = {
  song: string
  trackDuration: number
  numElements: number
  maxScore: number
}
export type PlayStatusScoreInfo = {
  gamerId: string
  score: number
  topScore: number
  topScoreRank: ScoreRank
  runningScoreRank?: ScoreRank
  counters: ScoreCounters
  doneCount: number
  errorCount: number
  scoreSeconds: number
  numLaps?: number
  isNewHighScore?: boolean
}
export type PlayStatus = PlayStatusTrackInfo &
  PlayStatusScoreInfo & {
    isPlaying?: boolean
    timestamp: number
  }

export type PlayStatusMap = { [slug: string]: PlayStatus }

export type TopScoreStat = {
  playerId: string
  playerName: string
  playStatus: PlayStatus
}
export type TrackStats = {
  trackSlug: string
  username: string
  playerTopScores: TopScoreStat[]
}
export type TrackStatsMap = { [trackSlug: string]: TrackStats }

export type PlayActions = {
  // blast: (isLeft: boolean) => void
  rewindToStart: () => void
  rewindTo: (time: number) => void
  clear: () => void
  forget: () => void
  delete: () => void
}

export enum LoadStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type LoadingPhase =
  | 'clearing'
  | 'track'
  | 'info'
  | 'text'
  | 'timing'
  | 'audio'
  | 'image'
  | 'processing'

export const LoadingPhases: LoadingPhase[] = [
  'clearing',
  'track',
  'info',
  'text',
  'timing',
  'audio',
  'image',
  'processing',
]

export type AudioFileType = { type: string }
export type UploadHelpers = {
  getLocalAudioFile: () => File | undefined
  getLocalImageFile: () => File | undefined
  getCurrentLRC: () => string
}
export type TrackActions = {
  chooseLocalFile: () => void
  chooseLocalImageFile: () => void
}

export type ContentVariant = 'dev' | 'test' | 'prod'

export type Options = {
  contentVariant: ContentVariant
  clearStorage: boolean
}

export type VizType = 'page' | 'text1' | 'spiral' | 'flower' | 'wave'
export type VizMap = {
  [key in VizType]: any
}
export type Point = { x: number; y: number }
export type Control = {}
export type ControlMap = { [key: string]: Control }
export type VisitorBaseProps = {
  nudge: Point
  activeWordColor: string
  passedLinesColor: string
  activeLineColor: string
  aheadLinesColor: string
}
export type VizVisitorBaseProps = VisitorBaseProps & {
  zoom: number
  step: number
  zoomRange: number
  zoomStep: number
}

export type TrackToPlaylistsMap = { [trackSlug: string]: string[] }

export type PackageAssetBase = {
  url: string
  type: string
  timestamp: number | undefined
}

export type HomedAssetBase = PackageAssetBase & {
  home: string
}
export type AudioAsset = HomedAssetBase & {
  mix: string
}
export type ImageAsset = HomedAssetBase & {
  blendMode: string
  theme: string
}
export type ScoreAsset = PackageAssetBase & {
  timing: PackageAssetBase[]
}

export type PackageData = {
  version: string
  slug: string
  home: string
  artist: string
  title: string
  attributes: {
    wordCount: number
    timedWordCount: number
    duration: number
    tempo: string
    tags: string[]
  }
  media: {
    audio: AudioAsset[]
    score: ScoreAsset[]
    image: ImageAsset[]
    links: string | undefined
  }

  timestamp: number
}

export type ControllerStatus = {
  controllerId: string
  playerId: string
  isActive: boolean
}
export type ControllerStatusMap = { [controllerId: string]: ControllerStatus }
export type BlastStatus = {
  isRight: boolean
  isPressed: boolean
  blastRank: ScoreRank
}
export type GamerStatus = {
  gamerId: string
  isActive: boolean
  playStatus: PlayStatus
  lastBlast: BlastStatus
  playerName: string
}
export type GamerStatusMap = { [gamerId: string]: GamerStatus }
