import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDown,
  faAngleRight,
  faList,
  faUser,
  faUsers,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { useEffect, useState } from 'react'

import {
  defaultMatchInfo,
  defaultMatchScore,
  HOST_VISIBILITY_DEMO,
  HOST_VISIBILITY_HIDE,
  HOST_VISIBILITY_PLAY,
} from '../../constants/constants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import modalsSlice from '../../reducers/modalsSlice'
import socialSlice from '../../reducers/socialSlice'
import { selectBlasterPeer } from '../../selectors/blaster-peer-selectors'
import { selectMatchStatus } from '../../selectors/match-selectors'
import {
  selectCurrentBlaster,
  selectCurrentUsername,
  selectPlayerVisibility,
} from '../../selectors/session-selectors'
import {
  selectIsContainerActive,
  selectIsMatchExpanded,
  selectIsShowMatchPlayers,
  selectMatchDisplayStyle,
} from '../../selectors/social-selectors'
import { MatchDisplayStyle, PlayerStatus } from '../../types'
import PeerMatchContainer from './PeerMatchContainer'
import { ScoreProgressBar } from './ProgressBar'

const matchStyleIcons: IconDefinition[] = []
matchStyleIcons[MatchDisplayStyle.BROWSE] = faUser
matchStyleIcons[MatchDisplayStyle.LEADERBOARD] = faUsers
matchStyleIcons[MatchDisplayStyle.MONITOR] = faList
type Props = { matchSlug: string }
const Match = ({ matchSlug: compoundMatchSlug }: Props) => {
  const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
  const matchStatus = useAppSelector(selectMatchStatus(matchOwner, matchSlug))
  const isActive = useAppSelector(selectIsContainerActive({ matchSlug: compoundMatchSlug }))
  const playerVisibility = useAppSelector(selectPlayerVisibility)
  const isShowChildren = useAppSelector(selectIsMatchExpanded({ matchSlug: compoundMatchSlug }))
  const isShowMatchPlayers = useAppSelector(
    selectIsShowMatchPlayers({ matchSlug: compoundMatchSlug })
  )
  const matchDisplayStyle = useAppSelector(
    selectMatchDisplayStyle({ matchSlug: compoundMatchSlug })
  )
  const currUsername = useAppSelector(selectCurrentUsername)
  const currBlaster: string = useAppSelector(selectCurrentBlaster)[0]?.gamerId || currUsername
  const matchPeer = useAppSelector(selectBlasterPeer(matchOwner))
  const dispatch = useAppDispatch()
  const [orderedPlayers, setOrderedPlayers] = useState<string[]>([])
  const fullCurrBlasterSlug = `${currUsername}/${currBlaster}`
  const { ownedMatchSlug = '??' } = matchPeer || {}
  const {
    info: matchInfo = defaultMatchInfo(),
    players: matchPlayers = {},
    leaderboard = [],
  } = matchStatus
  const { title: matchTitle = 'Unknown', hostVisibility = HOST_VISIBILITY_DEMO } = matchInfo
  const isOwnedMatch = matchOwner === currUsername
  const isRootOwnedMatch = compoundMatchSlug === ownedMatchSlug

  useEffect(() => {
    if (!compoundMatchSlug) {
      return
    }
    const { isShowGuestPlayers, isShowUserPlayers, isShowHostPlayer } = playerVisibility
    const players = [...leaderboard]
    const isCurrUserOnLeaderboard = players.includes(fullCurrBlasterSlug)
    if (!isOwnedMatch || isRootOwnedMatch) {
      if (!isCurrUserOnLeaderboard) {
        players.push(fullCurrBlasterSlug)
      }
      return setOrderedPlayers(players)
    }
    const isLeaveHost = hostVisibility === HOST_VISIBILITY_PLAY && isShowHostPlayer
    const filteredPlayers = players.filter((compoundPlayer: string) => {
      const [username, player] = compoundPlayer.split('/')
      const playerIsGuest = username === matchOwner && player !== matchOwner
      const playerIsHost = username === matchOwner && !playerIsGuest
      const playerIsOtherUser = !(playerIsGuest || playerIsHost)
      if (playerIsGuest && !(isShowGuestPlayers && isShowMatchPlayers)) {
        return false
      } else if (playerIsOtherUser && !(isShowUserPlayers && isShowMatchPlayers)) {
        return false
      } else if (playerIsHost && !isLeaveHost) {
        return false
      } else {
        return true
      }
    })
    if (!filteredPlayers.length && hostVisibility !== HOST_VISIBILITY_HIDE) {
      filteredPlayers.push(fullCurrBlasterSlug)
    }
    setOrderedPlayers(filteredPlayers)
  }, [
    compoundMatchSlug,
    playerVisibility,
    fullCurrBlasterSlug,
    hostVisibility,
    isShowMatchPlayers,
    leaderboard,
    matchOwner,
    isOwnedMatch,
    isRootOwnedMatch,
  ])
  const getPlayerContainers = () => {
    return orderedPlayers.map((compoundPlayer: string, index: number) => {
      const [username, player] = compoundPlayer.split('/')
      const userPlayers = matchPlayers[username] || {}
      const playerMatchStatus = userPlayers[player] || {
        matchScore: defaultMatchScore(),
      }
      const key = `${compoundPlayer}-${compoundMatchSlug}`
      return (
        <PeerMatchContainer
          key={key}
          username={player}
          userRank={index + 1}
          matchInfo={matchInfo}
          matchPeer={matchPeer}
          matchSlug={compoundMatchSlug}
          playerMatchStatus={playerMatchStatus}
        />
      )
    })
  }
  const onMatchClick = () => {
    dispatch(modalsSlice.actions.toggleMatchInfo(compoundMatchSlug))
  }
  const getExpander = () => {
    const onClick = () => {
      const isExpanded = !isShowChildren
      if (isRootOwnedMatch || !isShowMatchPlayers) {
        dispatch(
          socialSlice.actions.toggleUserExpanded({
            username: currUsername,
            matchSlug: compoundMatchSlug,
            isExpanded,
          })
        )
      }
      dispatch(
        socialSlice.actions.toggleMatchExpanded({
          matchSlug: compoundMatchSlug,
          isExpanded,
        })
      )
    }
    const helpTitle = `${isShowChildren ? 'Collapse' : 'Expand'} 'Match'`
    return (
      <button className="chatTreeExpander" onClick={onClick} title={helpTitle}>
        <FontAwesomeIcon icon={(isShowChildren ? faAngleDown : faAngleRight) as IconProp} />
      </button>
    )
  }
  const getNextDisplayStyle = (currStyle: MatchDisplayStyle) => {
    return currStyle === MatchDisplayStyle.BROWSE
      ? MatchDisplayStyle.LEADERBOARD
      : currStyle === MatchDisplayStyle.LEADERBOARD
      ? MatchDisplayStyle.MONITOR
      : MatchDisplayStyle.BROWSE
  }
  const getShowMatchPlayersToggle = () => {
    const onClick = () => {
      dispatch(
        socialSlice.actions.setMatchDisplayStyle({
          matchSlug: compoundMatchSlug,
          matchDisplayStyle: getNextDisplayStyle(matchDisplayStyle),
        })
      )
    }
    const helpTitle = `Solo / Leaderboard / Monitor'}`
    const icon = matchStyleIcons[matchDisplayStyle] as IconProp
    return (
      <button onClick={onClick} title={helpTitle}>
        <FontAwesomeIcon icon={icon} size="sm" />
      </button>
    )
  }
  const getCurrUserMatchStatus = () => {
    if (matchDisplayStyle === MatchDisplayStyle.MONITOR) {
      return null
    }
    const currUserPlayers = matchPlayers[currUsername] || {}
    const { [currUsername]: currUserScore = {} } = currUserPlayers
    const getRank = () => {
      const isHostCompeting = hostVisibility === HOST_VISIBILITY_PLAY
      const isDisplayRank = !isOwnedMatch || isHostCompeting || currUsername !== currBlaster
      if (!isDisplayRank) {
        return ''
      }
      const playerKey = `${currUsername}/${currBlaster}`
      const leaderboardPosition = orderedPlayers.indexOf(playerKey)
      const oneBasedLeaderboardPosition = leaderboardPosition + 1
      return oneBasedLeaderboardPosition > 0 ? `#${oneBasedLeaderboardPosition}` : '-'
    }
    const { matchScore = defaultMatchScore() } = currUserScore as PlayerStatus
    const scoreClass = cx({
      chatSongSummaryTopScore: true,
      // active: false, // isActive,
      match: isShowMatchPlayers,
    })
    if (isShowMatchPlayers) {
      return <div className={scoreClass}>{getRank()}</div>
    }
    return (
      <div className={scoreClass}>
        <ScoreProgressBar scoreInfo={matchScore} isUseTopScore={true} isMini />
      </div>
    )
  }

  const matchStatusClass = cx({
    chatStatusDiv: true,
    match: true,
    active: isActive,
  })

  return (
    <div className="chatDiv">
      <div className={matchStatusClass}>
        <div className="left">
          {getExpander()}
          <div className="title" onClick={onMatchClick} title="Match Info...">
            <div>{matchTitle}</div>
            {!isOwnedMatch && <div className="owner">host: {matchOwner}</div>}
          </div>
        </div>
        <div className="right">
          {!isRootOwnedMatch && getCurrUserMatchStatus()}
          {!isRootOwnedMatch && getShowMatchPlayersToggle()}
        </div>
      </div>
      {isShowChildren && getPlayerContainers()}
    </div>
  )
}

export default Match
