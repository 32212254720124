import { pathOr } from 'ramda'

import { RootState } from '../reducers'
import { MatchDisplayStyle } from '../types'

export type SidebarPathPayload = {
  username?: string
  matchSlug?: string
  playlistSlug?: string
  trackSlug?: string
}

const selectIsContainerActive =
  ({ username, matchSlug, playlistSlug, trackSlug }: SidebarPathPayload) =>
  (state: RootState) => {
    const path = []
    if (matchSlug) {
      path.push(matchSlug)
      if (username) {
        path.push('players')
        path.push(username)
        if (playlistSlug) {
          path.push('playlists')
          path.push(playlistSlug)
          if (trackSlug) {
            path.push('tracks')
            path.push(trackSlug)
          }
        }
      }
    }
    path.push('isActive')
    return pathOr(false, path, state.social)
  }

const selectIsMatchExpanded =
  ({ matchSlug = '' }: SidebarPathPayload) =>
  (state: RootState) =>
    pathOr(false, [matchSlug, 'isExpanded'], state.social)

const selectIsShowMatchPlayers =
  ({ matchSlug = '' }: SidebarPathPayload) =>
  (state: RootState) => {
    const matchDisplayStyle = selectMatchDisplayStyle({ matchSlug })(state)
    return matchDisplayStyle === MatchDisplayStyle.LEADERBOARD
  }

const selectMatchDisplayStyle =
  ({ matchSlug = '' }: SidebarPathPayload) =>
  (state: RootState) =>
    pathOr(MatchDisplayStyle.BROWSE, [matchSlug, 'matchDisplayStyle'], state.social)

const selectIsUserMatchExpanded =
  ({ username = '', matchSlug = '' }: SidebarPathPayload) =>
  (state: RootState) =>
    pathOr(false, [matchSlug, 'players', username, 'isExpanded'], state.social)

const selectIsUserPlaylistExpanded =
  ({ username = '', matchSlug = '', playlistSlug = '' }: SidebarPathPayload) =>
  (state: RootState) =>
    pathOr(
      false,
      [matchSlug, 'players', username, 'playlists', playlistSlug, 'isExpanded'],
      state.social
    )

const selectIsUserTrackExpanded =
  ({ username = '', matchSlug = '', playlistSlug = '', trackSlug = '' }: SidebarPathPayload) =>
  (state: RootState) =>
    pathOr(
      false,
      [
        matchSlug,
        'players',
        username || '',
        'playlists',
        playlistSlug || '',
        'tracks',
        trackSlug || '',
        'isExpanded',
      ],
      state.social
    )

const selectIsPlaylistTrackExpanded =
  ({ matchSlug = '', playlistSlug = '', trackSlug = '' }: SidebarPathPayload) =>
  (state: RootState) =>
    pathOr(
      false,
      [matchSlug, 'playlists', playlistSlug || '', 'tracks', trackSlug || '', 'isExpanded'],
      state.social
    )

// const selectIsShowUserMatchActiveScore =
//   ({ username, matchSlug }: MatchPath) =>
//   (state: RootState) =>
//     pathOr(false, [matchSlug, 'players', username, 'isShowActiveScore'], state.social)
// const selectIsShowUserPlaylistActiveScore =
//   ({ username, matchSlug, playlistSlug }: PlaylistPath) =>
//   (state: RootState) =>
//     pathOr(
//       false,
//       [matchSlug, 'players', username, 'playlists', playlistSlug, 'isShowActiveScore'],
//       state.social
//     )

const selectIsShowUserTrackActiveScore =
  ({ username = '', matchSlug = '', playlistSlug = '', trackSlug = '' }: SidebarPathPayload) =>
  (state: RootState) =>
    pathOr(
      false,
      [
        matchSlug,
        'players',
        username || '',
        'playlists',
        playlistSlug || '',
        'tracks',
        trackSlug || '',
        'isShowActiveScore',
      ],
      state.social
    )

export {
  selectIsMatchExpanded,
  selectIsShowMatchPlayers,
  selectMatchDisplayStyle,
  selectIsUserMatchExpanded,
  selectIsUserPlaylistExpanded,
  selectIsUserTrackExpanded,
  selectIsShowUserTrackActiveScore,
  selectIsContainerActive,
  selectIsPlaylistTrackExpanded,
}
