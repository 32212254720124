import { pathOr } from 'ramda'

import { defaultPlayerScore, defaultTrackInfo, HOST_VISIBILITY_HIDE } from '../constants/constants'
import { RootState } from '../reducers'
import {
  MatchPath,
  MatchStatus,
  PlayStatus,
  PlayStatusMap,
  ScoreCounters,
  ScoreRank,
  TopScoreStat,
  TrackInfo,
  TrackInfoMap,
  TrackStatsMap,
} from '../types'
import Util from '../util/util'
import { selectTrackInfo } from './blaster-peer-selectors'
import { selectMatchStatus } from './match-selectors'
import { selectPlayerVisibility } from './session-selectors'

export type LeaderboardCell = {
  slug: string
  // row: number
  // col: number
  title: string
  score: number
  scoreRank?: ScoreRank
  trackInfo?: TrackInfo
}
export type LeaderboardRow = LeaderboardCell[]
export type LeaderboardData = {
  colLabels: LeaderboardRow
  rowLabels: LeaderboardRow
  dataRows: LeaderboardRow[]
  playerCounts: { guestCount: number; othersCount: number; hasHost: boolean }
}

export const selectMatchStatsByTrack =
  ({ matchOwner, matchSlug }: MatchPath) =>
  (state: RootState): LeaderboardData => {
    const matchStatus = selectMatchStatus(matchOwner, matchSlug)(state)
    const {
      info: { hostVisibility, playlistOrder, playlists },
      leaderboard,
      players: playerScores,
    } = matchStatus
    const colLabels: LeaderboardRow = []
    const { isShowGuestPlayers, isShowHostPlayer, isShowUserPlayers } =
      selectPlayerVisibility(state)
    const isShowHost = isShowHostPlayer && hostVisibility !== HOST_VISIBILITY_HIDE
    const filteredPlayerIds = leaderboard.reduce(
      (acc: { username: string; playerId: string }[], compoundPlayerSlug: string) => {
        const [username, playerId] = compoundPlayerSlug.split('/')
        if (username === matchOwner) {
          if (playerId === matchOwner) {
            if (!isShowHost) {
              return acc
            }
          } else if (!isShowGuestPlayers) {
            return acc
          }
        } else if (!isShowUserPlayers) {
          return acc
        }
        return [...acc, { username, playerId }]
      },
      []
    )
    const playerCounts = filteredPlayerIds.reduce(
      (acc, { username, playerId }) => {
        if (username === matchOwner) {
          if (playerId === matchOwner) {
            if (isShowHost) {
              return { ...acc, hasHost: true }
            }
          } else if (isShowGuestPlayers) {
            return { ...acc, guestCount: acc.guestCount + 1 }
          }
        } else if (!isShowUserPlayers) {
          return { ...acc, othersCount: acc.othersCount + 1 }
        }
        return acc
      },
      { guestCount: 0, othersCount: 0, hasHost: false }
    )

    const scoredTracks = filteredPlayerIds.reduce((acc, { username, playerId }) => {
      const { trackScores } = playerScores[username][playerId]
      Object.keys(trackScores).forEach((trackSlug) => {
        const { topScore } = trackScores[trackSlug]
        if (topScore > 0) {
          acc.add(trackSlug)
        }
      })
      return acc
    }, new Set<string>())
    filteredPlayerIds.forEach(({ playerId, username }, index) => {
      const { name, matchScore } = playerScores[username][playerId]
      colLabels.push({
        // row: 0,
        // col: index + 1,
        slug: playerId,
        title: name || playerId,
        score: matchScore.topScore,
        scoreRank: matchScore.topScoreRank,
      })
    })
    const rowLabels: LeaderboardRow = []
    const dataRows: LeaderboardRow[] = []
    // let rowIndex = 1 // 0th row is column headers
    playlistOrder.forEach((playlistSlug, playlistIndex) => {
      const { title, trackOrder } = playlists[playlistSlug]
      rowLabels.push({
        // row: rowIndex++,
        // col: 0,
        slug: playlistSlug,
        title,
        score: 0,
      })
      const playlistRow: LeaderboardRow = filteredPlayerIds.map(({ username, playerId }, index) => {
        const { playlistScores } = playerScores[username][playerId]
        const playlistScore = playlistScores[playlistSlug]
        return {
          row: 0,
          col: index + 1,
          slug: playerId,
          title: '',
          score: playlistScore?.topScore || 0,
          scoreRank: playlistScore?.topScoreRank,
        }
      })
      dataRows.push(playlistRow)

      trackOrder.forEach((trackSlug, index) => {
        if (scoredTracks.has(trackSlug)) {
          const trackInfo = selectTrackInfo(matchOwner, trackSlug)(state) || defaultTrackInfo
          rowLabels.push({
            // row: rowIndex++,
            // col: 0,
            slug: trackSlug,
            title: trackInfo.title,
            trackInfo,
            score: 0,
          })
          const trackRow: LeaderboardRow = filteredPlayerIds.map(
            ({ username, playerId }, index) => {
              const { trackScores } = playerScores[username][playerId]
              const trackScore = trackScores[trackSlug]
              return {
                // row: rowIndex++,
                // col: index + 1,
                slug: trackSlug,
                title: '',
                score: trackScore?.topScore || 0,
                scoreRank: trackScore?.topScoreRank,
              }
            }
          )
          dataRows.push(trackRow)
        }
      })
    })
    return { colLabels, dataRows, rowLabels, playerCounts }
  }

export type TrackPlayerCell = {
  slug: string
  title: string
  score: number
  counters?: ScoreCounters
  scoreRank?: ScoreRank
  trackInfo?: TrackInfo
}
export type TrackPlayerRow = TrackPlayerCell[]
export type TrackPlayerData = {
  colLabels: TrackPlayerRow
  rowLabels: TrackPlayerRow
  dataRows: TrackPlayerRow[]
  playerCounts: { guestCount: number; othersCount: number; hasHost: boolean }
}

/*
  Collect the visible playerIds - their names will be the row labels
  Collect all their scored tracks that are in the given playlist - their track badges will be the column labels
    TODO: extra-match data, e.g. top score ever?
  The row cells will be each player's score and counters (if any, else empty) for each track
  column ordering: by round, then by most recently played track (TODO: and/or max track points, max achieved, etc.?)
  row ordering: round's player order
  columns with no results are omitted
 */
export const selectMatchStatsByPlayer =
  (matchOwner: string, matchSlug: string, playlistSlug: string, guestOrder: string[]) =>
  (state: RootState): TrackPlayerData => {
    const compoundMatchSlug = `${matchOwner}/${matchSlug}`
    const matchStatus = selectMatchStatus(matchOwner, matchSlug)(state)
    const {
      info: { hostVisibility, playlists, playlistOrder },
      leaderboard,
      players: playerScores,
    } = matchStatus
    const playlistSlugs = playlistSlug ? [playlistSlug] : playlistOrder
    const isLeaderboard = !playlistSlug
    const rowLabels: TrackPlayerRow = []
    const { isShowGuestPlayers, isShowHostPlayer, isShowUserPlayers } =
      selectPlayerVisibility(state)
    const isShowHost = isShowHostPlayer && hostVisibility !== HOST_VISIBILITY_HIDE
    const workingPlayerOrder = guestOrder.length ? guestOrder : leaderboard // TODO: support round-leader order as well?
    const filteredPlayerOrder = workingPlayerOrder.reduce(
      (acc: { username: string; playerId: string }[], compoundPlayerSlug: string) => {
        const [username, playerId] = compoundPlayerSlug.split('/')
        if (username === matchOwner) {
          if (playerId === matchOwner) {
            if (!isShowHost) {
              return acc
            }
          } else if (!isShowGuestPlayers) {
            return acc
          }
        } else if (!isShowUserPlayers) {
          return acc
        }
        return [...acc, { username, playerId }]
      },
      []
    )
    const playerCounts = filteredPlayerOrder.reduce(
      (acc, { username, playerId }) => {
        if (username === matchOwner) {
          if (playerId === matchOwner) {
            if (isShowHost) {
              return { ...acc, hasHost: true }
            }
          } else if (isShowGuestPlayers) {
            return { ...acc, guestCount: acc.guestCount + 1 }
          }
        } else if (!isShowUserPlayers) {
          return { ...acc, othersCount: acc.othersCount + 1 }
        }
        return acc
      },
      { guestCount: 0, othersCount: 0, hasHost: false }
    )

    const scoredTrackTimestamps: { [slug: string]: number } = {}
    const scoredPlaylists: { [slug: string]: boolean } = {}
    filteredPlayerOrder.forEach(({ username, playerId }) => {
      const { trackScores, playlistScores } = playerScores[username][playerId]
      Object.keys(playlistScores).forEach((playlistSlug) => {
        const { topScore } = playlistScores[playlistSlug]
        if (topScore > 0) {
          scoredPlaylists[playlistSlug] = true
        }
      })
      Object.keys(trackScores).forEach((trackSlug) => {
        const currTimestamp =
          trackSlug in scoredTrackTimestamps ? scoredTrackTimestamps[trackSlug] : 0
        const { topScore, timestamp } = trackScores[trackSlug]
        if (topScore > 0 && timestamp > currTimestamp) {
          scoredTrackTimestamps[trackSlug] = timestamp
        }
      })
    })
    const sortedScoreTracks = Object.keys(scoredTrackTimestamps).sort(
      (trackSlugA: string, trackSlugB: string) =>
        scoredTrackTimestamps[trackSlugA] - scoredTrackTimestamps[trackSlugB]
    )
    const colLabels: TrackPlayerRow = []
    const scoredTrackInfoByPlaylist: TrackInfoMap[] = []
    const filterPlaylistSlugs = playlistSlugs.filter(
      (playlistSlug) => !!scoredPlaylists[playlistSlug]
    )
    filterPlaylistSlugs.forEach((playlistSlug, playlistIndex) => {
      const { title: playlistTitle, trackOrder } = playlists[playlistSlug]
      colLabels.push({
        slug: playlistSlug,
        title: isLeaderboard ? playlistTitle : 'Round',
        score: 0,
      })
      const filteredScoredTracks = sortedScoreTracks.filter((trackSlug) => {
        return trackOrder.includes(trackSlug)
      })
      const scoredTrackInfo: TrackInfoMap = filteredScoredTracks.reduce((acc, trackSlug) => {
        const trackInfo = selectTrackInfo(matchOwner, trackSlug)(state) || defaultTrackInfo
        return { ...acc, [trackSlug]: trackInfo }
      }, {})
      filteredScoredTracks.forEach((trackSlug: string, index) => {
        const trackInfo = scoredTrackInfo[trackSlug]
        colLabels.push({
          slug: trackSlug,
          title: trackInfo.title,
          trackInfo,
          score: 0,
        })
      })
      scoredTrackInfoByPlaylist[playlistIndex] = scoredTrackInfo
    })
    colLabels.push({
      slug: compoundMatchSlug,
      title: 'Match',
      score: 0,
    })
    filteredPlayerOrder.forEach(({ playerId, username }, index) => {
      const { name } = playerScores[username][playerId]
      rowLabels.push({
        slug: playerId,
        title: name || playerId,
        score: 0,
      })
    })
    const dataRows = filteredPlayerOrder.map(({ playerId, username }, index) => {
      const { trackScores, playlistScores, matchScore } = playerScores[username][playerId]
      const {
        topScore: matchTopScore,
        topScoreRank: matchTopScoreRank,
        counters: matchCounters,
      } = matchScore || defaultPlayerScore()
      const matchCell = {
        slug: compoundMatchSlug,
        title: '',
        score: matchTopScore || 0,
        counters: matchCounters,
        scoreRank: matchTopScoreRank,
      }
      const trackRow: TrackPlayerRow = []
      filterPlaylistSlugs.forEach((playlistSlug, playlistIndex) => {
        const { topScore, topScoreRank, counters } =
          playlistScores[playlistSlug] || defaultPlayerScore()
        const playlistCell = {
          slug: playlistSlug,
          title: '',
          score: topScore || 0,
          counters,
          scoreRank: topScoreRank,
        }
        trackRow.push(playlistCell)
        Object.keys(scoredTrackInfoByPlaylist[playlistIndex]).forEach(
          (trackSlug: string, index) => {
            const trackInfo = scoredTrackInfoByPlaylist[playlistIndex][trackSlug]
            const { topScore, topScoreRank, counters } =
              trackScores[trackSlug] || defaultPlayerScore()
            const trackCell = {
              slug: trackSlug,
              title: '',
              score: topScore || 0,
              counters,
              scoreRank: topScoreRank,
              trackInfo,
            }
            trackRow.push(trackCell)
          }
        )
      })
      trackRow.push(matchCell)
      return trackRow
    })
    return { colLabels, dataRows, rowLabels, playerCounts }
  }

const compareScores = (
  { playStatus: { topScore: topScoreA } }: TopScoreStat,
  { playStatus: { topScore: topScoreB } }: TopScoreStat
) => {
  return topScoreB - topScoreA
}

export const selectTrackStats =
  ({ matchOwner, matchSlug }: MatchPath) =>
  (state: RootState): TrackStatsMap => {
    const { isShowUserPlayers, isShowHostPlayer, isShowGuestPlayers } =
      selectPlayerVisibility(state)
    const allTrackStats: TrackStatsMap = {}
    const matchStatus: MatchStatus = selectMatchStatus(matchOwner, matchSlug)(state)
    const {
      players,
      info: { hostVisibility },
    } = matchStatus
    const isHideHostHost = !isShowHostPlayer || hostVisibility === HOST_VISIBILITY_HIDE
    Object.keys(players).forEach((username) => {
      const isOtherUser = username !== matchOwner
      if (isOtherUser && !isShowUserPlayers) {
        return
      }
      const playerIds = Object.keys(players[username])
      playerIds.forEach((playerId) => {
        if (playerId === matchOwner && isHideHostHost) {
          return
        }
        if (playerId !== username && !isShowGuestPlayers) {
          return
        }
        const playerName: string = pathOr(playerId, [username, playerId, 'name'], players)
        const playerTrackScores: PlayStatusMap = pathOr(
          {},
          [username, playerId, 'trackScores'],
          players
        )
        Object.keys(playerTrackScores).forEach((trackSlug) => {
          const playerTrackScore = playerTrackScores[trackSlug] as PlayStatus
          const currTrackStat = allTrackStats[trackSlug]
          const { playerTopScores = [] } = currTrackStat || {}
          const newStat = { playerName, playerId, playStatus: playerTrackScore }
          Util.insertIntoSortedArray(playerTopScores, newStat, compareScores)
          allTrackStats[trackSlug] = { trackSlug, username, playerTopScores }
        })
      })
    })

    return allTrackStats
  }
