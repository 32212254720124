import React from 'react'

import socialSlice from '../../reducers/socialSlice'
import { selectIsUserPlaylistExpanded } from '../../selectors/social-selectors'
import BlastRecord from './BlastRecord'
import { MatchInfo, PlaylistInfo, TrackStatsMap } from '../../types'
import { useAppDispatch, useAppSelector } from '../../hooks'
import TrackWithMatchPlayers from './TrackWithMatchPlayers'

type Props = {
  username: string
  matchInfo: MatchInfo
  playlistInfo: PlaylistInfo
  trackScores: TrackStatsMap
}

const PlaylistContainerWithTrackPlayers = ({
  username,
  matchInfo,
  playlistInfo,
  trackScores = {},
}: Props) => {
  const dispatch = useAppDispatch()
  const { slug: playlistSlug, trackOrder = [] } = playlistInfo // shouldn't have to default... TODO
  const { slug: matchSlug } = matchInfo
  const isShowChildren = useAppSelector(
    selectIsUserPlaylistExpanded({ username, matchSlug, playlistSlug })
  )
  const onToggleChildren = () => {
    const isExpanded = !isShowChildren
    dispatch(
      socialSlice.actions.togglePlaylistExpanded({
        isLeft: false,
        username,
        matchSlug,
        playlistSlug,
        isExpanded,
      })
    )
  }
  const getTracksWithPlayers = () => {
    const usableTracks = trackOrder.filter((trackSlug) => {
      if (!(trackSlug in trackScores)) {
        return false
      }
      return trackSlug in trackScores
    })
    return usableTracks.map((trackSlug) => {
      const trackStats = trackScores[trackSlug]
      return (
        <TrackWithMatchPlayers
          key={`${username}-${matchSlug}-${playlistSlug}-${trackSlug}`}
          matchSlug={matchSlug}
          matchInfo={matchInfo}
          playlistInfo={playlistInfo}
          trackStats={trackStats}
        />
      )
    })
  }
  return (
    <React.Fragment>
      <BlastRecord
        username={username}
        matchInfo={matchInfo}
        playlistInfo={playlistInfo}
        onToggleChildren={onToggleChildren}
        isChildrenVisible={isShowChildren}
        hasDetails={false}
      />
      {isShowChildren && getTracksWithPlayers()}
    </React.Fragment>
  )
}

export default PlaylistContainerWithTrackPlayers
