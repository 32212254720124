import cx from 'classnames'
import React from 'react'

import { activateNextPane } from '../../actions/play-actions'
import newTrackAction from '../../actions/track-loading/newTrack'
import { Pane, PaneClasses } from '../../constants/constants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import modalsSlice from '../../reducers/modalsSlice'
import sessionSlice from '../../reducers/sessionSlice'
import { selectIsFocus } from '../../reducers/focusSlice'
import { selectMatchGroups } from '../../selectors/match-selectors'
import { selectCurrentUsername } from '../../selectors/session-selectors'
import { isMobile } from '../../util/track-utils'
import ModalBackdrop from '../modals/ModalBackdrop'
import Match from './Match'

const SocialContainer = () => {
  const dispatch = useAppDispatch()
  const currUsername = useAppSelector(selectCurrentUsername)
  const { ownedMatchSlug, hostedMatchSlugs, playingMatchSlugs, hiddenMatchSlugs } =
    useAppSelector(selectMatchGroups)
  const isCurrFocus = useAppSelector(selectIsFocus(Pane.SIDEBAR))
  const isAuthoring = !!ownedMatchSlug
  const getMatchGroup = (orderedMatchSlugs: string[]) => {
    const matchContainers = orderedMatchSlugs.map((compoundMatchSlug: string) => {
      return <Match key={compoundMatchSlug} matchSlug={compoundMatchSlug} />
    })
    return matchContainers
  }
  const joinMatch = () => dispatch(modalsSlice.actions.toggleJoinMatchModal(true))
  const getMatchGroups = () => {
    const newMatch = () => dispatch(modalsSlice.actions.toggleMatchInfo(`${currUsername}/`))
    const newTrack = () => dispatch(newTrackAction())
    return (
      <>
        <div className="matchGroup">
          <div>Collections</div>
          <button className="chatNewTrackButton" onClick={newTrack}>
            New Track...
          </button>
        </div>
        <div>{getMatchGroup([ownedMatchSlug])}</div>
        <div className="matchGroup">
          <div>Hosting</div>
          <button className="chatNewTrackButton" onClick={newMatch}>
            New Match...
          </button>
        </div>
        <div>{getMatchGroup(hostedMatchSlugs)}</div>
        <div className="matchGroup">
          <div>Playing</div>
          <button className="chatNewTrackButton" onClick={joinMatch}>
            Join Match...
          </button>
        </div>
        <div>{getMatchGroup(playingMatchSlugs)}</div>
        <div className="matchGroup">Archived</div>
        <div>{getMatchGroup(hiddenMatchSlugs)}</div>
      </>
    )
  }
  const getContents = () => {
    return (
      <div id="transcript" className="transcript">
        {!isAuthoring && (
          <div>
            <button className="chatNewTrackButton" onClick={joinMatch}>
              Join Match...
            </button>
          </div>
        )}
        {isAuthoring ? getMatchGroups() : getMatchGroup(playingMatchSlugs)}
      </div>
    )
  }
  const useModal = isMobile() // TODO: make optional for desktop?
  if (useModal) {
    const close = () => {
      dispatch(sessionSlice.actions.setIsShowSocial(false))
    }
    return (
      <ModalBackdrop isTop onClick={close}>
        <div className="settingsContainer socialModal">{getContents()}</div>
      </ModalBackdrop>
    )
  }

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // console.log(`container: ${PaneClasses[Pane.SIDEBAR]}`)
    dispatch(activateNextPane({ pane: Pane.SIDEBAR }))
  }
  const className = cx(PaneClasses[Pane.SIDEBAR], { hasFocus: isCurrFocus })
  return (
    <div className={className} onClick={onClick}>
      {getContents()}
    </div>
  )
}

export default SocialContainer
