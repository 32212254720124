import React from 'react'

import { useAppSelector } from '../../hooks'
import { selectAudience } from '../../selectors/session-selectors'
import { selectOwnedMatchSlug } from '../../selectors/blaster-peer-selectors'
import userManager from '../../services/UserManager'
import { AudienceType } from '../../types'

const allAudienceLabels = {
  pub: 'Pub',
  couch: 'Couch',
  solo: 'Solo',
  prod: 'Pro',
}
const basicAudienceDisplayOrder: AudienceType[] = ['pub', 'couch', 'solo']
const allAudienceDisplayOrder: AudienceType[] = ['prod', ...basicAudienceDisplayOrder]

const AudienceSwitch = () => {
  const audience = useAppSelector(selectAudience)
  const ownedMatchSlug = useAppSelector(selectOwnedMatchSlug)
  const isProducer = !!ownedMatchSlug
  const workingOrder = isProducer ? allAudienceDisplayOrder : basicAudienceDisplayOrder
  const workingIndex = workingOrder.indexOf(audience)
  const onClick = () => {
    const nextIndex = (workingIndex + 1) % workingOrder.length
    const nextAudience = workingOrder[nextIndex]
    userManager.audience = nextAudience
  }
  return (
    <div className="audienceSwitch">
      <button onClick={onClick}>Mode: {allAudienceLabels[audience]}</button>
    </div>
  )
}

export default AudienceSwitch
